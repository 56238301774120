html,
body,
#root {
  height: 100%;
  width: 100%;

}




body {
  margin: 0;
  font-size: 46px;
}

*,
*:after,
*:before {
  box-sizing: border-box;
}