* {
  font-family: 'Prompt', sans-serif;
  font-weight: 400;
  font-size: 1.3rem;
  max-width: 100vw;
  max-height: 100vh;
  overflow: hidden;
  /* background-color: rgba(0, 0, 0, 0.669); */
}

.container {
  background: rgb(204, 209, 253);
  cursor: url('https://uploads.codesandbox.io/uploads/user/b3e56831-8b98-4fee-b941-0e27f39883ab/Ad1_-cursor.png') 39 39,
    auto;
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: center;
  text-align: center;
  line-height: 1;
  overflow: hidden;
}

.name {
  font-size: 2rem;
  user-select: none;
}

.encord {
  font-size: 1.2rem;
  user-select: none;
}

.italic {
  font-style: italic;
  font-size: 0.7rem;
  user-select: none;
}

.text {
  user-select: none;
}

.deck {
  position: absolute;
  width: 70vw;
  height: 50vh;
  will-change: transform;
  display: flex;
  align-items: center;
  justify-content: center;
  touch-action: none;
  overflow: visible;
}

.deck>div {
  background-color: #FAF9F6;
  background-size: 80%;
  overflow: visible;
  background-repeat: no-repeat;
  background-position: center center;
  width: 20vw;
  /* max-width: 150px; */
  height: 50vh;
  /* max-height: 285px; */
  will-change: transform;
  border-radius: 20px;
  box-shadow: 0 12.5px 100px -10px rgba(50, 50, 73, 0.4), 0 10px 10px -10px rgba(50, 50, 73, 0.3);
}

@media only screen and (max-width: 1200px) {

  /*Tablets [601px -> 1200px]*/
  .deck>div {
    background-color: #FAF9F6;
    background-size: 80%;
    overflow: visible;
    background-repeat: no-repeat;
    background-position: center center;
    width: 30vw;
    /* max-width: 150px; */
    height: 50vh;
    /* max-height: 285px; */
    will-change: transform;
    border-radius: 20px;
    box-shadow: 0 12.5px 100px -10px rgba(50, 50, 73, 0.4), 0 10px 10px -10px rgba(50, 50, 73, 0.3);
  }
}

@media only screen and (max-width: 600px) {

  /*Big smartphones [426px -> 600px]*/
  .deck>div {
    background-color: #FAF9F6;
    background-size: 80%;
    overflow: visible;
    background-repeat: no-repeat;
    background-position: center center;
    width: 50vw;
    /* max-width: 150px; */
    height: 50vh;
    /* max-height: 285px; */
    will-change: transform;
    border-radius: 20px;
    box-shadow: 0 12.5px 100px -10px rgba(50, 50, 73, 0.4), 0 10px 10px -10px rgba(50, 50, 73, 0.3);
  }
}

@media only screen and (max-width: 425px) {

  /*Small smartphones [325px -> 425px]*/
  .deck>div {
    background-color: #FAF9F6;
    background-size: 80%;
    overflow: visible;
    background-repeat: no-repeat;
    background-position: center center;
    width: 60vw;

    /* max-width: 150px; */
    height: 50vh;
    /* max-height: 285px; */
    will-change: transform;
    border-radius: 20px;
    box-shadow: 0 12.5px 100px -10px rgba(50, 50, 73, 0.4), 0 10px 10px -10px rgba(50, 50, 73, 0.3);
  }
}